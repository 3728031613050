<template>
  <div class="d-flex flex-column align-items-center text-center main-container">
    <div v-if="this.subscriptionPopup === true" class="blur"></div>
    <div v-if="this.subscriptionPopup === true" class="d-flex flex-column align-items-center popup-container col-9 col-sm-7 col-md-5 col-lg-4 col-xl-3">
      <img @click="toggleSubscribePopUp()" class="col-1 close-popup-btn" src="../assets/btn-close.png">
      <div class="popup-header-text mt-3 col-10">Subscribe now & stand to WIN your share of R65 000 airtime, monthly!</div>
      <div class="mt-3 mb-3 col-10">Pick your favorite players from the biggest teams across the globe or predict the score of your favorite teams & be rewarded when they perform out on the pitch!
      </div>
      <button @click="routeToSubscribe()" class="subscribe-popup-button col-11 mb-4">Subscribe for R3/day</button>
    </div>
    <div class="d-flex flex-column align-items-center fixed-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div>
        <div class="welcome-text mt-2">WELCOME</div>
        <div class="pick-game-text">Pick your game and start WINNING today!</div>
      </div>
      <div class="d-flex flex-column align-items-center" v-if="user.status === 'Subscribed'">
        <img @click="freePlayPredictorClicked()" src="../assets/freeplay-predictor-btn.png" class="game-button">
        <img @click="fantasyFootballButtonClicked()" src="../assets/fantasy-football-btn-icon.png" class="game-button">
        <img @click="predictWinButtonClicked()" src="../assets/predict-win-btn-icon.png" class="game-button">
      </div>
      <div class="d-flex flex-column align-items-center" v-if="user.status !== 'Subscribed'">
        <img @click="freePlayPredictorClicked()" src="../assets/freeplay-predictor-btn.png" class="col-7">
        <div class="d-flex flex-column align-items-center border col-9 subscribe-container">
          <img @click="toggleSubscribePopUp()" src="../assets/btn-info.png" class="col-1 info-btn">
          <img @click="routeToSubscribe()" src="../assets/btn-fantasy-football-thin.png" class="col-10 mt-3">
          <img @click="routeToSubscribe()" src="../assets/btn-predict-and-win-thin.png" class="col-10 mt-1">
          <div class="subscribe-text col-11 mt-2"><strong>Subscribe today for just R3/day</strong> and gain access to <strong>both</strong> Fantasy Football and Predict & Win.</div>
          <div @click="termsConditionsClicked()" class="term-conditions-text mt-1 mb-2 text-decoration-underline">T&C Apply | Subscription Service.</div>
        </div>
      </div>
      <div v-if="user.status === 'Subscribed'" class="d-flex flex-row justify-content-center mt-3 col-12">
        <div><a class="color #0d6efd" @click="routeToHowToPlay"><u>How to play</u></a> |
          <a v-if="!miniAppModel.miniAppMode" href="https://termsandconditions.vodacomfantasy.com/" target="_blank"><u>T's & C's</u></a>
          <a v-if="miniAppModel.miniAppMode" class="color #0d6efd" @click="miniAppTermsAndConditionClicked"><u>T's & C's</u></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {environmentConfig} from "@/config/environmentConfig";
import {mapState} from "vuex";
import LoginService from "@/services/loginService";

export default {
  name: "GameSelection",
  data() {
    return {
      miniAppModel: {
        miniAppMode: undefined,
        vodapayUserId: undefined,
        miniAppPromoPopup: true,
      },
      subscriptionPopup: false,
    }
  },
  computed: {
    ...mapState(['encryptedMsisdn', 'token', 'user', 'promoPopup']),
  },
  methods: {
    routeToHowToPlay() {
      this.$router.push('/how-to-play');
    },
    freePlayPredictorClicked() {
      this.openUrlWithTokenOrWithout(this.token, environmentConfig.freePlayHost);
    },
    predictWinButtonClicked() {
      this.openUrlWithTokenOrWithout(this.token, environmentConfig.predictorHost);
    },
    fantasyFootballButtonClicked() {
      this.openUrlWithTokenOrWithout(this.token, environmentConfig.fantasyHost);
    },
    termsConditionsClicked() {
      this.openUrlWithTokenOrWithout(this.token, environmentConfig.termsConditionsHost);
    },
    openUrlWithTokenOrWithout(token, host) {
      const url = token ? `${host}?fantasyToken=${token}` : host;
      window.open(url, '_self');
    },
    async checkTokenValid() {
      const user = await LoginService.getUserStatus(`${this.token}`);
      if (user.data.token === 'invalidToken') {
        localStorage.removeItem("token");
        localStorage.removeItem("msisdn");
        this.$router.push('/login');
      }
    },
    setMiniAppParams() {
      this.miniAppModel.miniAppMode = this.user?.miniAppMode ?? this.miniAppModel.miniAppMode;
      this.miniAppModel.vodapayUserId = this.user?.vodapayUserId ?? this.miniAppModel.vodapayUserId;
    },
    miniAppTermsAndConditionClicked() {
      window.open(`https://termsandconditions.vodacomfantasy.com/?miniAppMode=${this.miniAppModel.miniAppMode}&vodapayUserId=${this.miniAppModel.vodapayUserId}`, '_self');
    },
    toggleSubscribePopUp() {
      this.subscriptionPopup = !this.subscriptionPopup;
    },
    async routeToSubscribe() {
      await LoginService.subscribeDoiOne(this.user.msisdn);
      this.$router.push('/subscribe');
    },
  },
  async beforeMount() {
    await this.checkTokenValid();
    this.setMiniAppParams();
  },
}
</script>

<style scoped>
.blur {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(1px);
  top: 0;
  height: 100vh;
  width: 100vh;
  z-index: 1;
}

.main-container {
  position: fixed;
  top: 55px;
  overflow-y: hidden;
  background-image: url('../assets/onboarding-background.jpg');
  min-height: 100%;
  background-size: 390px 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.popup-container {
  position: relative;
}

.close-popup-btn {
  position: absolute;
  top: -9px;
  right: -8px;
}

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #FFFFFF;
  font-size: 14.5px;
}

.promotional-popup-container {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cricket-close-popup-btn {
  z-index: 2;
  position: absolute;
  top: 50px;
  right: -30px;
  width: 30px;
}

.subscribe-popup-button {
  background-color: #bfa668;
  color: #FFFFFF;
  border: 0;
  height: 50px;
  font-size: 19px;
}

.fixed-container {
  position: fixed;
  top: 48%;
}

.welcome-text {
  font-size: 20px;
  font-weight: 700;
}

.pick-game-text {
  font-size: 12px;
  font-weight: 700;
}

.game-button {
  margin-bottom: -20px;
  width: 250px;
}

.subscribe-container {
  position: relative;
  background-color: #d3d3d3;
  border-radius: 6px;
}

.subscribe-text {
  font-size: 12px;
}

.term-conditions-text {
  font-size: 10px;
}

.info-btn {
  position: absolute;
  right: -10px;
  top: -10px;
}

.popup-header-text {
  font-weight: 700;
}

.cricket-popup-img {
  width: 250px;
}

@media only screen and (max-width: 400px) {
  .game-button {
    margin-bottom: -20px;
    width: 60%;
  }
}

</style>
