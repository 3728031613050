const productionConfig = {
    vodaPayIntegrationHost: "https://utils.vodacomfantasy.com/vodapay-integration",
    assetHost: "https://fanclash.blob.core.windows.net/fanclash-web-portal/vodacom-sa/V5",
    brand: "vodacom",
    scorePredictorService: "https://predictor.vodacomfantasy.com/score-predictor-service",
    upstreamIntegrationService: "https://utils.vodacomfantasy.com/upstream",
    fantasyHost: "https://fantasysoccer.vodacomfantasy.com",
    predictorHost: "https://predictor.vodacomfantasy.com",
    freePlayHost: "https://freepredictor.vodacomfantasy.com",
    rugbyHost: "https://rugby.vodacomfantasy.com",
    appEnv: "production",
    vodacomIntegratiojnService: "https://vodacomfantasy.com/utils-service",
    hyveAwsUrl: "partners-api.hyvesdp.com",
    proxyServiceUrl: "https://utils.vodacomfantasy.com/utils/proxy",
    infinityApi: "https://vodacomfantasy.com/api",
    hyveAwsAccessKey: "AKIAVPFRGEIV4VAFLWWU",
    hyveAwsAccessSecret: "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",
    hyveAwsApiKey: "J02RsCij3e8ykFf1FZIJN1z8bCfYMG5U8Lt1CdGD",
    rubgyRedirectUrl: "http://vodacomfantasyrugby.com/home/HyveSubscribed",
    utilsService: "https://utils.vodacomfantasy.com/utils",
    termsConditionsHost: "https://termsandconditions.vodacomfantasy.com/"
}

const stagingConfig = {
    vodaPayIntegrationHost: "https://utils.vodacomfantasy.com/vodapay-integration",
    assetHost: "https://fanclash.blob.core.windows.net/fanclash-web-portal/vodacom-sa/V5",
    brand: "vodacom",
    scorePredictorService: "https://staging.predictor.vodacomfantasy.com/score-predictor-service",
    upstreamIntegrationService: "https://staging.utils.vodacomfantasy.com/upstream",
    fantasyHost: "https://staging.fantasysoccer.vodacomfantasy.com",
    predictorHost: "https://staging.predictor.vodacomfantasy.com",
    freePlayHost: "https://freepredictor.vodacomfantasy.com",
    rugbyHost: "https://rugby.vodacomfantasy.com",
    appEnv: "production",
    vodacomIntegratiojnService: "https://vodacomfantasy.com/utils-service",
    hyveAwsUrl: "partners-api.hyvesdp.com",
    proxyServiceUrl: "https://utils.vodacomfantasy.com/utils/proxy",
    infinityApi: "https://vodacomfantasy.com/api",
    hyveAwsAccessKey: "AKIAVPFRGEIV4VAFLWWU",
    hyveAwsAccessSecret: "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",
    hyveAwsApiKey: "J02RsCij3e8ykFf1FZIJN1z8bCfYMG5U8Lt1CdGD",
    rubgyRedirectUrl: "http://vodacomfantasyrugby.com/home/HyveSubscribed",
    utilsService: "https://utils.vodacomfantasy.com/utils",
    termsConditionsHost: "https://termsandconditions.vodacomfantasy.com/"
}

const environment = getEnvironnment()

export const environmentConfig = getEnvObject();


function getEnvironnment() {
    var base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}
